import { BrowserRouter, Routes, Route } from "react-router";
import Imprint from "./pages/Imprint";
import Home from "./pages/Home";
import DataProtection from "./pages/DataProtection";
import { ThemeProvider } from "styled-components";
import Theme from "./Theme";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/impressum" element={<Imprint />} />
          <Route path="/datenschutz" element={<DataProtection />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
