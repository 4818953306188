import LogoSymbol from "./svg/LogoSymbol";
import styled from "styled-components";
import Theme from "../Theme";
import { Link } from "react-router";

function Footer() {
  return (
    <FooterContainer>
      <LogoContainer>
        <StyledLogoSymbol />
      </LogoContainer>
      <StyledLink to="/impressum">Impressum</StyledLink>
      <StyledLink to="/datenschutz">Datenschutz</StyledLink>
    </FooterContainer>
  );
}

export default Footer;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  padding: 2rem;
  align-items: center;
  color: ${Theme.colors.light};
  background-color: ${Theme.colors.dark};

  & > :not(:last-child) {
    margin-right: 2rem;
  }

  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
  }
`;

const LogoContainer = styled.div`
  flex-grow: 1;
`;

const StyledLogoSymbol = styled(LogoSymbol)`
  height: 2rem;
  color: ${Theme.colors.light};
  opacity: 0.3;
`;

const StyledLink = styled(Link)`
  color: ${Theme.colors.light};
  opacity: 0.6;
  text-decoration: none;
`;
